/** @jsx jsx */
import "../scss/ckeditor-styles.scss";

import {css, jsx} from "@emotion/core";
import SEO from "../components/seo";
import LayoutErwachsene from "../components/erwachsene-layout";

const pageStyle = css`
    font-size: 1rem;
    width: 100%;
    article {
        width: 80%;
        @media screen and (max-width: 1024px) {
            width: 100%;
            padding: 0 1rem;
            box-sizing: border-box;
        }
        img {
            max-width: 100%;
        }
    }
`;

const UnterseiteErwachsene = ({pageContext}) => {

    return <LayoutErwachsene longPage={true}>
        <SEO title={pageContext.titel} />
        <div css={pageStyle} dangerouslySetInnerHTML={{__html: pageContext.text}} />
    </LayoutErwachsene>
};

export default UnterseiteErwachsene;